@import '@styles/styles.scss';

.stepper {
    width: 100%;
    margin: 0;
    border-radius: 12px;

    .stepperProgressBarWrapper {
        display: flex;
        .stepperProgressBar {
            height: 10px;
            background-color: $COLOR_ICEBERG_BLUE;

            &.markedStepperProgressBar {
                background-color: $COLOR_EASTERN_BLUE;
            }
        }
    }

    .stepperContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 20px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        @include tablet {
            width: auto;
            margin-left: 60px;
            margin-right: 60px;
        }

        @include mobileLarge {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    .stepIndex {
        font-size: $FONTSIZE_20;

        .currStepIndex {
            font-size: $FONTSIZE_36;
        }
    }
    .stepName {
        font-size: $FONTSIZE_24;
    }

    @include mobile {
        .stepIndex {
            font-size: $FONTSIZE_14;
            .currStepIndex {
                font-size: $FONTSIZE_24;
            }
        }
        .stepName {
            font-size: $FONTSIZE_16;
        }
    }
}
