@import '@styles/styles.scss';

.itemField {
    &.sellerName .itemTags {
        .itemTag {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}
