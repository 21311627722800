@import '@styles/styles.scss';

.qrCodeBoardWrapper {
    .qrCodeImg {
        padding: 15px;
        background-color: $infosBkColor;
        border: 3px solid $primaryColor;
        border-radius: 8px;
    }
}
