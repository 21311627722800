@import '@styles/styles.scss';

.amountInfosWrapper {
    .infosWrapper {
        @include tablet {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .mainInfosItemsWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include tablet {
            display: flex;
            flex-direction: column;
        }
    }

    .rightInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .realAmount {
            .itemFieldLeft {
                .numeric {
                    color: $primaryColor;
                }

                .unit {
                    color: $primaryColor;
                }
            }
        }

        @include tablet {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .remindMsg {
        &.remindMsgRight {
            text-align: right;

            @include tablet {
                text-align: left;
            }
        }
    }
}
