@import '@styles/styles.scss';

.itemTagGroupMerged {
    border-color: $primaryColor;
    margin-top: 5px;

    .itemTag {
        button {
            color: $primaryColor;
            background-color: transparent;
        }

        & + .itemTag {
            &:before {
                color: $primaryColor;
            }
        }
    }
}
