@import '@styles/styles.scss';

.infosHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-bottom: 60px;
    text-align: center;

    @include mobileLarge {
        padding: 40px 15px 0;
        margin-bottom: 0;
    }

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        .title {
            font-size: $FONTSIZE_40;
            margin-top: 20px;
        }

        .tickIco,
        .processIco {
            color: $COLOR_GREEN;
        }

        .cancelIco {
            color: $COLOR_DANGERRED;
        }

        .waitingIco {
            color: $primaryColor;
        }
    }

    .message {
        font-size: $FONTSIZE_24;
    }

    @include mobile {
        .headline {
            .title {
                font-size: $FONTSIZE_30;
            }

            .iconHead {
                width: 48px;
            }
        }

        .message {
            font-size: $FONTSIZE_16;
        }
    }
}
