@import '@styles/styles.scss';

.actionsDialogContent {
    margin: 0 0 0 20px;
    .contentWrapper {
        align-items: flex-start;

        .title,
        .mainContent {
            text-align: left;
        }
    }

    .dialogActionGroup {
        justify-content: flex-start;
        flex-direction: row-reverse;
        margin-bottom: 40px;

        @include mobileLarge {
            flex-direction: row;
        }

        .btn {
            height: 54px;
            width: 235px;

            & + .btn {
                margin-right: 8px;
            }
        }
    }
}
