@import '@styles/styles.scss';

.headerWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 26px;
    padding-bottom: 26px;
    margin-bottom: 50px;

    // &:after {
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     height: 2px;
    //     width: 100%;
    //     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    // }

    .logoBrandWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 1200px;
        margin-right: 20px;
    }
}
